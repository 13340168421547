<template>
  <Hero
    heroTitle="Build high performing  <br class='hidden xl:inline-block' /> remote teams with  <br class='hidden xl:inline-block' /> handpicked, world-  <br class='hidden xl:inline-block' /> class talent"
    heroDesc="We are a 360 solution to find, hire, onboard, and <br class='hidden xl:inline-block' /> retain top 1% highly skilled African talents"
    heroBtnText="Work with us"
    :heroBtnLink="$employerUrl"
    heroImg="hero3.png"
  />

  <Customers />

  <div class="flex justify-center bg-white">
    <div class="w-full px-[24px] md:px-0 md:w-[72vw]">
      <div class="py-[5rem]">
        <h2 class="text-center mb-3 text-[32px] md:text-[60px] text-brand-black leading-[110%] font-semibold">What talent does your company need?</h2>
        <div class="flex flex-wrap items-center justify-center mt-6 mb-4">
          <span
            class="inline-block py-3 px-4 md:px-8 bg-[#C7E8F9] text-[#292C26] text-[15px] md:text-[21px] text-center shadow-md rounded-lg mr-3 mb-2"
            >Sales Rep</span
          >
          <span
            class="inline-block py-3 px-4 md:px-8 bg-[#C7E8F9] text-[#292C26] text-[15px] md:text-[21px] text-center shadow-md rounded-lg mr-3 mb-2"
            >Customer Support</span
          >
          <span
            class="inline-block py-3 px-4 md:px-8 bg-[#C7E8F9] text-[#292C26] text-[15px] md:text-[21px] text-center shadow-md rounded-lg mr-3 mb-2"
            >Designer</span
          >
          <span
            class="inline-block py-3 px-4 md:px-8 bg-[#C7E8F9] text-[#292C26] text-[15px] md:text-[21px] text-center shadow-md rounded-lg mr-3 mb-2"
            >Recruiter</span
          >
          <span class="inline-block py-3 px-4 md:px-8 bg-[#C7E8F9] text-[#292C26] text-[15px] md:text-[21px] text-center shadow-md rounded-lg mb-2"
            >Content Writer</span
          >
        </div>
        <p class="text-light-grey/50 text-[16px] md:text-[30px] text-center mb-6">Hiring great talent to join your team doesn’t have to be hard</p>
      </div>
    </div>
  </div>

  <div class="flex justify-center bg-brand-black">
    <div class="w-full px-[24px] md:px-0 md:w-[72vw]">
      <div class="py-[5rem]">
        <h2 class="text-center text-[32px] md:text-[60px] text-white leading-[110%] font-semibold mb-8">Hear what others are saying</h2>

        <div class="px-8 mb-8">
          <div class="h-[2px] bg-white"></div>
        </div>

        <div class="md:flex gap-10 pb-5 justify-center">
          <div class="lg:w-1/3 bg-white rounded-3xl px-6 py-8 mb-3 md:mb:0">
            <p class="text-brand-black font-light mb-6">
              “CareerBuddy is the best-kept secret in the world of Startup recruiting without a doubt. We can’t recommend them enough. We have tried a
              lot of recruiting partners even "high profile" ones but with little or no success. CareerBuddy makes recruitment look easy. They
              understand our business and they continue to help us with some of our most critical roles”
            </p>

            <div class="flex items-center mb-4">
              <img src="@/assets/images/testify3.jpeg" class="w-[50px] h-[50px] rounded-full mr-3" alt="" />
              <h3 class="font-medium text-[20px] pt-2">Anu, Founder - Sabi</h3>
            </div>
          </div>

          <div class="lg:w-1/3 bg-white rounded-3xl px-6 py-8">
            <p class="text-brand-black font-light mb-6">
              When it comes to Start-up hiring, CareerBuddy is your go-to company. They understand the assignment and even exceed expectations. They
              take the time to elicit the requirements and find the right candidates for the roles. We’re particularly relieved about the fact that we
              can trust CareerBuddy’s pre-screening processes to be thorough. We’ll obviously be repeat clients of CareerBuddy.
            </p>

            <div class="flex items-center mb-4">
              <img src="@/assets/images/testify4.jpeg" class="w-[50px] h-[50px] rounded-full mr-3" alt="" />
              <h3 class="font-medium text-[20px] pt-2">Mudia, CEO/Founder- Matta</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex justify-center bg-white">
    <div class="w-full px-[24px] md:px-0 md:w-[72vw]">
      <div class="py-[5rem]">
        <h2 class="text-center mb-3 text-[32px] md:text-[60px] text-brand-black leading-[110%] font-semibold">How CareerBuddy helps you</h2>
        <p class="text-light-grey/50 text-[16px] md:text-[30px] text-center mb-[2rem]">
          Use any of our talent services to grow your team without any of the headaches.
        </p>

        <div class="md:grid md:grid-cols-2 lg:grid-cols-3 gap-10 pb-5">
          <div class="mb-4 md:mb-0 bg-white rounded-2xl p-8">
            <div class="bg-brand-black w-[59px] h-[59px] inline-flex items-center justify-center rounded-lg p-3">
              <img src="@/assets/icons/book.svg" alt="" />
            </div>

            <p class="text-[18px] md:text-[30px] text-brand-black my-3 py-[.2rem] block">Head Hunting</p>

            <p class="text-brand-black mb-8 font-light">
              Use our Headhunting service and talent self service to fill your roles quickly. Save time and resources combing through hundreds of
              applications. Fill open positions in under 21 days.
            </p>

            <div class="mb-3">
              <a href="/talent-placement" class="rounded-xl px-8 py-4 text-center bg-brand-black text-white">Start Hiring</a>
            </div>
          </div>

          <div class="mb-4 md:mb-0 bg-white rounded-2xl p-8">
            <div class="bg-brand-black w-[59px] h-[59px] inline-flex items-center justify-center rounded-lg p-3">
              <img src="@/assets/icons/book.svg" alt="" />
            </div>

            <p class="text-[18px] md:text-[30px] text-brand-black my-3 py-[.2rem] block">Outsourcing</p>

            <p class="text-brand-black mb-8 font-light">
              We help you hire and manage your remote team. Manage payroll, handle everything from tax compliance to salary disbursements, ensuring
              your team is paid accurately and on time.
            </p>

            <div class="mb-3">
              <a href="" class="rounded-xl px-8 py-4 text-center bg-brand-black text-white">Tell me more...</a>
            </div>
          </div>

          <div class="mb-4 md:mb-0 bg-white rounded-2xl p-8">
            <div class="bg-brand-black w-[59px] h-[59px] inline-flex items-center justify-center rounded-lg p-3">
              <img src="@/assets/icons/book.svg" alt="" />
            </div>

            <p class="text-[18px] md:text-[30px] text-brand-black my-3 py-[.2rem] block">Embedded Recruiter</p>

            <p class="text-brand-black mb-8 font-light">
              Eliminate hefty agency fees by transitioning to a subscription-based hiring model.Use our more cost-effective, predictable, and
              transparent method for managing your recruitment expenses
            </p>

            <div class="mb-3">
              <a href="" class="rounded-xl px-8 py-4 text-center bg-brand-black text-white">Learn more...</a>
            </div>
          </div>

          <div class="mb-4 md:mb-0 bg-white rounded-2xl p-8">
            <div class="bg-brand-black w-[59px] h-[59px] inline-flex items-center justify-center rounded-lg p-3">
              <img src="@/assets/icons/book.svg" alt="" />
            </div>

            <p class="text-[18px] md:text-[30px] text-brand-black my-3 py-[.2rem] block">Employer Branding</p>

            <p class="text-brand-black mb-8 font-light">
              Elevate your employer branding to effectively reach and attract talent possessing the precise skills and competencies necessary for
              contributing to your organization's success.
            </p>

            <div class="mb-3">
              <a href="" class="rounded-xl px-8 py-4 text-center bg-brand-black text-white">Tell me more...</a>
            </div>
          </div>

          <div class="mb-4 md:mb-0 bg-white rounded-2xl p-8">
            <div class="bg-brand-black w-[59px] h-[59px] inline-flex items-center justify-center rounded-lg p-3">
              <img src="@/assets/icons/book.svg" alt="" />
            </div>

            <p class="text-[18px] md:text-[30px] text-brand-black my-3 py-[.2rem] block">Talent Marketplace</p>

            <p class="text-brand-black mb-8 font-light">
              Leverage our unrivaled Africa-wide talent network. Get matched with pre-vetted, qualified candidates
            </p>

            <div class="mb-3">
              <a href="" class="rounded-xl px-8 py-4 text-center bg-brand-black text-white">Tell me more...</a>
            </div>
          </div>

          <div class="bg-white rounded-2xl p-8">
            <div class="bg-brand-black w-[59px] h-[59px] inline-flex items-center justify-center rounded-lg p-3">
              <img src="@/assets/icons/book.svg" alt="" />
            </div>

            <p class="text-[18px] md:text-[30px] text-brand-black my-3 py-[.2rem] block">Promote Your Brand</p>

            <p class="text-brand-black mb-8 font-light">
              Utilise our media platforms to increase visibility of your brand to our community of professionals across Africa and reach the right
              audience
            </p>

            <div class="mb-3">
              <a href="" class="rounded-xl px-8 py-4 text-center bg-brand-black text-white">Show me how...</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Steps />

  <WhoWeHelp />

  <div class="flex justify-center bg-light-yellow">
    <div class="w-full px-[24px] md:px-0 md:w-[78vw]">
      <div class="py-[5rem]">
        <h2 class="mb-6 text-[30px] text-brand-black leading-[110%] font-semibold">
          What Fast Growing Startups are saying about <br class="hidden md:inline-block" />
          CareerBuddy
        </h2>

        <div class="md:grid md:grid-cols-2 lg:grid-cols-3 gap-10 pb-5">
          <div class="bg-white rounded-2xl p-8 mb-3 md:0">
            <p class="text-brand-black font-light mb-6">
              "CareerBuddy helped us build a great team that has contributed to our success as a company. They took the time to understand our needs
              and provided us with candidates that were highly skilled and matched our company's culture. We are grateful for their partnership."
            </p>

            <div class="flex items-center mb-4">
              <img src="@/assets/images/moniepoint2.png" class="w-[50px] h-[50px] mr-3" alt="" />
              <h3 class="font-medium text-[20px] pt-2">Moniepoint</h3>
            </div>
          </div>

          <div class="bg-white rounded-2xl p-8 mb-3 md:0">
            <p class="text-brand-black font-light mb-6">
              “CareerBuddy is the go-to partner for startup hiring. Their team helped us navigate the complex process of hiring top talent and
              provided us with candidates that exceeded our expectations. They made the recruitment process seamless, and we would highly recommend
              them to any startup looking to build a great team."
            </p>

            <div class="flex items-center mb-4">
              <img src="@/assets/images/ehealth.png" class="w-[50px] h-[50px] mr-3" alt="" />
              <h3 class="font-medium text-[20px] pt-2">Ehealth Africa</h3>
            </div>
          </div>

          <div class="bg-white rounded-2xl p-8">
            <p class="text-brand-black font-light mb-6">
              Working with CareerBuddy was a game-changer for us. Their team helped us navigate the complex process of hiring top talent and provided
              us with candidates that exceeded
            </p>

            <div class="flex items-center mb-4">
              <img src="@/assets/images/gamp.png" class="w-[50px] h-[50px] mr-3" alt="" />
              <h3 class="font-medium text-[20px] pt-2">Gamp</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Customers from '@/components/partials/Customers.vue';
import Hero from '@/components/partials/Hero.vue';
import Steps from '@/components/partials/Steps.vue';
import WhoWeHelp from '@/components/partials/WhoWeHelp.vue';
import { useMeta } from '@/utils/meta';

useMeta();
</script>
