<template>
  <div class="flex justify-center bg-light-yellow">
    <div class="w-full px-[24px] md:px-0 md:w-[72vw]">
      <div class="py-[5rem]">
        <h2 class="mb-3 text-[32px] md:text-[60px] text-brand-black leading-[110%] font-semibold">Who We Help</h2>
        <p class="text-light-grey/50 text-[18px] md:text-[30px] mb-[4rem]">
          Going global? Entering new markets in Africa? The talent is here - but recruiting and managing employees abroad has major risks and
          roadblocks.
        </p>

        <div class="bg-light-yellow md:bg-white p-4 md:p-8 rounded-md">
          <div
            class="flex flex-col md:flex-row items-start md:items-center justify-start md:justify-between gap-4 md:gap-16 border-0 md:border-b-2 border-brand-black/10 py-[1rem]"
          >
            <div class="w-full md:w-1/3 order-2 md:order-1">
              <h1 class="leading-[110%] text-[18px] md:text-[23px] text-brand-black">CEOs and Founder</h1>
            </div>
            <div class="w-full md:w-1/3 order-3 md:order-2">
              <p class="leading-[130%] text-brand-black">
                Hit the inquire button. Tell us about the role you're looking to fill. We'll get to know each other and the job you're looking to
                fill.
              </p>
            </div>
            <div class="w-full md:w-1/3 flex md:justify-end justify-start order-1 md:order-3">
              <img src="@/assets/images/leadership.svg" class="w-[40x] h-[40px] md:w-[90x] md:h-[90px]" alt="" />
            </div>
          </div>

          <div
            class="flex flex-col md:flex-row items-start md:items-center justify-start md:justify-between gap-4 md:gap-16 border-0 md:border-b-2 border-brand-black/10 py-[1rem]"
          >
            <div class="w-full md:w-1/3 order-2 md:order-1">
              <h1 class="leading-[110%] text-[18px] md:text-[23px] text-brand-black">
                HR and talent <br />
                Acquisition Leaders
              </h1>
            </div>
            <div class="w-full md:w-1/3 order-3 md:order-2">
              <p class="leading-[130%] text-brand-black">
                At mid-market enterprises who are in need of a full-service partner with an efficient and streamlined hiring process, to accelerate
                their recruitment efforts while staying legally compliant.
              </p>
            </div>
            <div class="w-full md:w-1/3 flex md:justify-end justify-start order-1 md:order-3">
              <img src="@/assets/images/humanresearch.svg" class="w-[40x] h-[40px] md:w-[90x] md:h-[90px]" alt="" />
            </div>
          </div>

          <div class="flex flex-col md:flex-row items-start md:items-center justify-start md:justify-between gap-4 md:gap-16 py-[1rem]">
            <div class="w-full md:w-1/3 order-2 md:order-1">
              <h1 class="leading-[110%] tetxt-[18px] md:text-[23px] text-brand-black">Executives</h1>
            </div>
            <div class="w-full md:w-1/3 order-3 md:order-2">
              <p class="leading-[130%] text-brand-black">
                At rapidly expanding businesses looking to hire great talent without compromising on quality and reduce time to hire by up to 60%.
              </p>
            </div>
            <div class="w-full md:w-1/3 flex md:justify-end justify-start order-1 md:order-3">
              <img src="@/assets/images/meetingroom.svg" class="w-[40x] h-[40px] md:w-[90x] md:h-[90px]" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
